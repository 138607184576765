

.footer-container{
    background-color: var(--skin);
    display:flex;
    flex-direction: column;
    align-items:flex-start;
    /* gap:2rem; */
   color:var(--primary-font-color);
  width: 100vw;
  /* background-color: antiquewhite; */
  padding: 3rem 2rem;

}

.footer-image{
    align-self: center;
    width:20rem;
    height: 10rem;
}
.footer-contact-container,
.footer-projects-container{
    width:100%;
    display:flex;
    text-align: left;
    flex-direction: column;
    
}
.footer-subheading,
.footer-terms-subheading{
    color:var(--primary-01);
    font-size: 1.5rem;
    font-weight: 550;
    width:100vw;
    border-bottom: 2px solid var(--primary-01);
    text-align: left;
    padding:0.8rem 0.2rem;
}

.footer-contact-content,
.footer-projects-content{
    width:100%;
    display:flex;
    flex-direction: column;
    gap:0.8rem;
    padding:1rem 0.5rem;
 
}
.footer-subheading2{
    font-size: large;
    font-weight: 550;
}
.footer-contact{
    font-size: large;
    font-weight: bold;
}
.footer-copyright{
    display:flex;
    flex-direction: column;
    text-align: left;
    gap:0.5rem;
}
.footer-subheading-content{
/* background-color: aqua; */
width:  90%;
}
@media only screen and (max-width:650px){
    .footer-container{
        padding: 10px;
    }
}
@media only screen and (min-width: 650px) {
    .footer-container{
        flex-direction: row;
        justify-content: center;
    }

    .footer-image{
        padding:2rem;
    }
    .footer-contact-content,
    .footer-projects-content,
    .footer-copyright{
        margin-top: 0;
        width:70%;
    }
    .footer-subheading{
        width:70%;
    }
    
}

