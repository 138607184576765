
.containerx{
    background-color: var(--bg-color-theme);
    height: 100%;
    padding: 5rem 10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin-top: 3rem; */
}

h1{
    margin-top: 3rem;
    margin-bottom: 2rem;
}
.aP{
    font-size: 1rem;
    /* color: maroon; */
    text-align: left;
}
p{
    margin-bottom: 1rem!important;
}
hr{
    /* width: 60%; */
    margin: auto;
}

/* ==========MEDIA QUERIES (small devices) ========= */
@media screen and (max-width:600px) {
   .containerx{
    height: 100%;
    width: 100%!important;
    padding: 0 20px;
    margin-top: 3rem;
   }
  
.aP{
    font-size: 0.8rem;
}
  }