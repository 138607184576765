.pop-up{
 color: black;
 width: 30rem;
 height: fit-content;
 padding: 2rem;
 background-color:  var(--bg-color-theme);
 z-index: 10;
margin: 4rem auto;
margin-left: 36%;
 border-radius: 10px;
 position: fixed;
}
.he{
    border-bottom: 3px solid maroon;
}
.hp{
    padding-top: 1rem ;
}
.pop-up-closed{
    display: none;
}
.butt{
    background-color: maroon;
    color: var(--bg-color-theme);
    border-radius: 4px;
    margin-top: 1rem;
    border: none;

}
@media screen and (max-width:600px){
    .pop-up{
        width: 90%;
        margin: 15% 5%;
    }
    .hp{
        font-size: small;
    }
}