/* *{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  } */
.mainDiv {
  overflow: hidden;
  /* background-color:rebeccapurple; */
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  /* margin-top: 0px; */
}

.leftWrapper {
  background-color: transparent;
  width: 40%;
  height: 100%;
}

.carouselDiv1 {
  height: 100vh;
  width: 60%;
  /* background-color: maroon; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.slide{
  width: 100%;
}
.slide>img {
  width: 100%;
  object-fit: fill;
  height: 100vh;
  padding-top: 5rem;
}

.carouselDiv2 {
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
/* background-color: aqua; */
}
.bn2{
  /* background-color: rgb(218, 200, 177); */
  width: fit-content;
  z-index: 2;
  position: absolute;
  border-radius: 10px;
  left: 16%;
  top: 80%;
}
.btnn{
  background-color: maroon;
  border: none;
  padding: 10px 20px;
  font-size: 20px;
  border-radius: 4px;
  color: #F3C286;
}

.m{
  color: #F3C286;
  font-weight: 900;
  transition: 300ms ease-in-out;
}
.btnn:hover>.m{
  padding-left: 15px;
}
.btnn:hover{
  box-shadow: rgba(240, 211, 46, 0.4) 5px 5px, rgba(240, 179, 46, 0.3) 10px 10px, rgba(240, 191, 46, 0.2) 15px 15px, rgba(240, 185, 46, 0.1) 20px 20px, rgba(240, 179, 46, 0.05) 25px 25px;
  transition: 300ms ease-in-out;
}
.slide2 {
  opacity: 0;
  transition: 1s;
  transform: translateX(100%);
  width: 100%;
  background-color: transparent;
  font-size: 2.5rem;
  font-weight: 900;
  font-style: italic;
  font-family: 'Merriweather', serif;
  /* color:maroon; */
  /* background-color: maroon; */
  background: linear-gradient(to right, rgb(245, 146, 59) 20%, maroon 40%, maroon 80%, rgb(245, 146, 59) 90%);
  background-size: 200% auto;
  color: #000;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  animation: shine 5s linear infinite;
}
  @keyframes shine {
    to {
      background-position: 200% center;
    }
  }


.slide2.active2 {
  transform: translateX(0%);
  opacity: 1;
  transition: 1s ease-in-out;
  width: 90%;
background-color: aquamarine;
}

.carouselDiv3 {
  background-color: transparent;
  height: 30%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.slide {
  opacity: 0;
  transition: 1s;
  transform: translateY(100%);
  width: 99%;
  object-fit: cover;
}

.slide.active {
  transform: translateY(0%);
  opacity: 1;
  transition: 1s ease-in-out;
  width: 99%
}

/* CSS */
.arrow-left,
.arrow-right {
  align-items: center;
  appearance: none;
  background-color: #fff;
  border-radius: 100%;
  border-style: none;
  box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px, rgba(0, 0, 0, .14) 0 6px 10px 0, rgba(0, 0, 0, .12) 0 1px 18px 0;
  box-sizing: border-box;
  color: #3c4043;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-family: "Google Sans", Roboto, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 80px;
  justify-content: center;
  letter-spacing: .25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 2px 24px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1), opacity 15ms linear 30ms, transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
  will-change: transform, opacity;
  z-index: 0;
}

.arrow-left:hover,
.arrow-right:hover {
  background: #F6F9FE;
  color: #174ea6;
}

.arrow-left:active,
.arrow-right:active {
  box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%), 0 8px 12px 6px rgb(60 64 67 / 15%);
  outline: none;
}

.arrow-left:focus,
.arrow-right:focus {
  outline: none;
  /* border: 2px solid #4285f4; */
}

.arrow-left:not(:disabled),
.arrow-right:not(:disabled) {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.arrow-left:not(:disabled):hover,
.arrow-right:not(:disabled):hover {
  box-shadow: rgba(60, 64, 67, .3) 0 2px 3px 0, rgba(60, 64, 67, .15) 0 6px 10px 4px;
}

.arrow-left:not(:disabled):focus,
.arrow-right:not(:disabled):focus {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.arrow-left:not(:disabled):active,
.arrow-right:not(:disabled):active {
  box-shadow: rgba(60, 64, 67, .3) 0 4px 4px 0, rgba(60, 64, 67, .15) 0 8px 12px 6px;
}

.arrow-left:disabled,
.arrow-right:disabled {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.banner {
  background-color: rgb(243, 194, 134);
  width: 17%;
  position: absolute;
  border-radius: 10px;
  right: 12%;
  top: 75%;
  box-shadow: rgba(240, 211, 46, 0.4) 5px 5px, rgba(240, 179, 46, 0.3) 10px 10px, rgba(240, 191, 46, 0.2) 15px 15px, rgba(240, 185, 46, 0.1) 20px 20px, rgba(240, 179, 46, 0.05) 25px 25px;
}


/* ########################################################################################## */

@media screen and (max-width:600px) {
  .mainDiv {
    flex-direction: column;
    /* background-color: #3c4043; */
    height: fit-content;
  }

  .carouselDiv1,
  .carouselDiv2,
  .carouselDiv3 {
    width: 100%;
  }
.carouselDiv1{
  height:20rem;
  /* background-color: #174ea6; */

}
.slide>img{
padding: 0;
height: 20rem;
margin: 0;
padding: 0;
}
  .carouselDiv2 {
    width: 100%;
    height: 15rem;
    /* margin-top: 1rem; */
/* background-color: burlywood; */
  }

  .carouselDiv3 {
    width: 100%;
   /* background-color: aqua; */
height: fit-content;
padding: 1rem 0;
  }

  .arrow-left,
  .arrow-right {
    height: 60px;
    width: 60px;
  }

  .slide2,
  .slide2.active2 {
    font-size: 20px;
    /* background-color: #3c4043; */
    /* margin-top: 13rem; */
  }

  .leftWrapper {
    width: 100%;
  }
  .bn2{
    margin: 20% 23%;
}
  .bn2, .bn2>img{
    /* width: 6rem; */
    height: 4rem;
    z-index: 0;
    position: inherit;
    margin: 0;
    margin-bottom: 2rem;
    padding: 0;
  }
.btnn{
 font-size: 20px;
width: fit-content;
}
.bannerHome>img{
  height: 90px;
  width: 200px;
}
.bannerHome>h1{
 font-size: 16px;
}
.bannerHome>h6{
 font-size: 16px;
}
.bannerHome{
 font-size: 16px;
}
}
