.card-title {
 
    text-transform: uppercase;
    font-family: "playfairdisplayregular";
    font-weight: 560;
    letter-spacing: 0.83px;
    font-size: 1.5rem;
    border-left: solid maroon 4px;
    padding-left: 1rem;
    margin-left: 1rem;
    color: maroon;
    /* background-color: rgb(243, 194, 134); */
  
  }
 body{
  background-color: rgb(243, 194, 134);

 }
  .card{
    border: none!important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    overflow: hidden;
  }
  .card-body{
    background-color: var(--skin);
  
    }

  .card:hover{
    transform: translateY(-13px);
    transition: 500ms  ease-in-out;
  }