
html{
  background-color: var(--bg-color-theme);
}
.App {
  text-align: center;
  background-color:var(--bg-color-theme);
  /* padding-bottom:2rem; */
}

.footer-text{
  color:var(--primary-01);
  background:var(--secondary-01)
}

.components{
  z-index: 1;
}
#no-scroll{
  pointer-events: none;
  
}
:root{
  --primary-font-color:black ;
  --primary-01:maroon;
  --secondary-01: rgb(255, 255, 255);
  --skin:rgb(248, 210, 165);
  --footer-bg: var(--secondary-01);
  --dark-gray:#838383;
  --white-color:white;
  --light-white-color:rgb(219, 249, 213);
  --bg-color-theme:rgb(255, 255, 255);
}




@media screen and (max-width:600px) {
  .App{
    padding: 0;
  }
}
