.navbar {
    width: 100vw !important;
    justify-content: center !important;
    font-weight: 600;
}
.bg-da{
    background-color: var(bg-color-theme)!important;
}
.navbar-nav .nav-link.active, .navbar-nav .show>.nav-link {
    color: maroon!important;
}
.navbar-nav:hover .nav-link.active:hover, .navbar-nav:hover .show>.nav-link:hover {
    color:black!important;
}

 .dropdown-item, .dropdown-menu{
    color: maroon!important;
    background-color: rgb(249, 238, 228)!important;
}
 .dropdown-item:hover, .dropdown-menu:hover{
    color: black!important;
}
.mx{
    padding-left: 14rem!important;
}

i {
    color: maroon;
    font-size: 25px;
    padding: 0 5px;
    cursor: pointer;
}


i:hover {
    color: black;
    transition: 500ms;
}

@media screen and (max-width:600px){
    .mx{
        padding-left: 0!important;
    }
} 