.wrapper-blurred{
    filter: blur(10px);
}
.loc{
    /* background-color: aqua; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.sc{
    /* background-color: blue; */
    padding: 0;
    margin: 7rem ; 
}

.sc >img{
    width: 20rem;
}
.walkHeader{
    margin: 70px auto;
    width: 40%;
}
@media screen and (max-width:600px){
    .loc{
        flex-direction: column;
    }
    .sc{
        margin: 2rem 0rem;
    }
    iFrame{
        width: 90%;
    }
    .walkHeader{
        /* background-color: aliceblue; */
        margin-top: 0!important;
        padding: 0;
        width: 80%;
    }
}