.florPlan{
    -webkit-transition: all 350ms ease;
    transition: all 350ms ease;
    cursor: pointer;

}
.florPlan:hover{
filter: opacity(.8);
}
.mod{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    background-color: black;
    transition: opacity .4s ease , visibility .4s ease, transform .5s ease-in-out;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    overflow: hidden;
    z-index: 999;
}
.mod.open{
    visibility: visible;
    opacity: 1;
    transform: scale(1);
}
.mod img{
width: auto;
max-width: 100%;
height: auto;
max-height: 100%;
display: block;
line-height: 0;
box-sizing: border-box;
padding: 20px 0 20px;
margin: 0 auto;
}
.mod > i{
    position: fixed;
    top: 10px;
    right: 10px;
   font-size: 2rem;
    padding: 5px;
    color: aliceblue;
    cursor: pointer;
}
.lenghtt{
    height: 20rem;
}