body{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--secondary-01);
}
.cont{
    margin-top: 3rem;
    background-color: var(--secondary-01)(0, 0, 0);
    display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;  
        height: 100%;  
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    }
.pic{
    width: 50px;
}
.one, .two, .three {
    padding: 1rem;
    /* background-color: aquamarine; */
    width:90% ;
}
.one:hover{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    transition: 500ms;
}
.two:hover{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    transition: 500ms;
}
.three:hover{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    transition: 500ms;
}
.c > form{
    margin-top: 3rem;
    height: 100%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
   
}

@media screen and (max-width:600px) {
    .c > form{
        margin: 3rem 0;
    }
    .row{
        padding: 0 1rem;
}
}
